import {List, Record} from "immutable";
import React from "react";

export function createModel(name, fromJson) {
    const Class = Record(fromJson({}), name);
    Class.fromServer = (json = {}) => new Class(fromJson(json));
    Class.fromServerList = (json = []) => List(json.map(Class.fromServer));

    return Class;
}

export const setFormErrors = (setError, errors) => Object.entries(errors).forEach(([field, errors]) => errors.forEach(error => setError(field, error)));
export const handleEmailUniqueError = (error) => {
    return error.type === 'unique' && <span>Email je již použit</span>;
};

export const identity = (x) => x;