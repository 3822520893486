// Actions
import {APP_ACTION_PREFIX} from "../utils/constants";
import {List, Map} from 'immutable';
import {doGet, doPatch, doPost} from "../core/fetch";
import User from "../models/User";

const ACTION_PREFIX = `${APP_ACTION_PREFIX}/users`;

const LOGIN = `${ACTION_PREFIX}/LOGIN`;
const LOGOUT = `${ACTION_PREFIX}/LOGOUT`;

const FETCH_IDENTITY = `${ACTION_PREFIX}/FETCH_IDENTITY`;
const FETCH_IDENTITY_SUCCESS = `${ACTION_PREFIX}/FETCH_IDENTITY_SUCCESS`;
const FETCH_IDENTITY_FAILED = `${ACTION_PREFIX}/FETCH_IDENTITY_FAILED`;

const UPDATE_IDENTITY = `${ACTION_PREFIX}/FETCH_IDENTITY`;
const VERIFY_EMAIL = `${ACTION_PREFIX}/VERIFY_EMAIL`;

// Reducer
const defaultState = Map({
    loggedUser: null,
    isFetching: false,
});

export default function reducer(state = defaultState, action = {}) {
    switch (action.type) {
        case FETCH_IDENTITY: return state.set('isFetching', true);
        case FETCH_IDENTITY_SUCCESS: return state.merge({loggedUser: action.payload, isFetching: false});
        case FETCH_IDENTITY_FAILED: return state.set('isFetching', false);
        case UPDATE_IDENTITY: return state.set('loggedUser', action.payload);
        case VERIFY_EMAIL: return state.set('loggedUser', action.payload);
        case LOGIN: return state.merge({isFetching: false, loggedUser: action.payload});
        case LOGOUT: return state.set('loggedUser', null);
        default: return state;
    }
}

// Action Creators
export function login(email, password) {
    return (dispatch) => doPost('/auth/login/', {email, password})
        .then((response) => dispatch({type: LOGIN, payload: User.fromServer(response.data.data)}));
}

export function logout() {
    return (dispatch) => doPost('/auth/logout')
        .then((response) => dispatch({type: LOGOUT }));
}

export function fetchIdentity() {
    return (dispatch) => {
        dispatch({type: FETCH_IDENTITY});
        doGet('/users/me')
            .then((response) => dispatch({type: FETCH_IDENTITY_SUCCESS, payload: User.fromServer(response.data.data)}))
            .catch(() => dispatch({type: FETCH_IDENTITY_FAILED}));
    }
}

export function updateIdentity(data) {
    return (dispatch) => doPatch('/users/me', data)
        .then((response) => dispatch({type: LOGIN, payload: User.fromServer(response.data.data)}));
}

export function verifyEmail(token) {
    return (dispatch) => doPatch('/users/verification', {token})
        .then((response) => dispatch({type: VERIFY_EMAIL, payload: User.fromServer(response.data.data)}));
}

// Selectors
export const isFetching = (state) => state.users.get('isFetching');
export const getLoggedUser = (state) => state.users.get('loggedUser');
