import React from 'react';
import ReactDOM from 'react-dom';
import App from './layout/App';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import reducer from './ducks';
import {Provider} from "react-redux";
import {identity} from "./utils/functions";
import {fetchIdentity} from "./ducks/loggedUser";

const enhancer = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : identity,
);

const store = createStore(reducer, enhancer);
store.dispatch(fetchIdentity());

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
