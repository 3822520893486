import React, {useEffect} from 'react';
import '../assets/sass/main.scss';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from "react-router-dom";

import LoginPage from "../pages/LoginPage"
import RegistrationPage from "../pages/RegistrationPage"
import EmailVerificationPage from "../pages/EmailVerificationPage"
import NotFoundPage from "../pages/NotFoundPage"
import {connect} from "react-redux";
import {getLoggedUser, isFetching, logout} from "../ducks/loggedUser";
import Spinner from "../components/Spinner";
import {bindActionCreators} from "redux";
import MainLayout from "./MainLayout";
import MyProfilePage from "../pages/MyProfilePage";
import UserInfoPage from "../pages/UserInfoPage";
import OrdersPage from "../pages/OrdersPage";
import UsersPage from "../pages/UsersPage";

const App = ({logout, loggedUser, isFetching}) => (
    <main>
        {isFetching ? <div className="l-form"><Spinner/></div> : (
            <Router basename={process.env.REACT_APP_BASE_PATH}>
                <Switch>
                    <Route exact path="/registrace" render={(props) => loggedUser ? <Redirect to="/"/> : <RegistrationPage {...props}/>}/>
                    <Route exact path="/prihlaseni" render={(props) => loggedUser ? <Redirect to="/"/> : <LoginPage {...props}/>}/>
                    <Route exact path="/verifikace" component={EmailVerificationPage}/>
                    <MainLayoutProtectedRoute exact path="/" loggedUser={loggedUser} logout={logout} component={() => <Redirect to="/muj-profil"/> } />
                    <MainLayoutProtectedRoute exact path="/muj-profil" loggedUser={loggedUser} logout={logout} component={MyProfilePage}/>
                    <MainLayoutProtectedRoute exact path="/objednavky" loggedUser={loggedUser} logout={logout} component={OrdersPage} />
                    <AdminMainLayoutProtectedRoute exact path="/uzivatele" loggedUser={loggedUser} logout={logout} component={UsersPage} />
                    <AdminMainLayoutProtectedRoute path="/uzivatele/:id" loggedUser={loggedUser} logout={logout} component={UserInfoPage} />

                    <Route component={NotFoundPage}/>
                </Switch>
            </Router>
        )}
    </main>
);


const MainLayoutProtectedRoute = ({loggedUser, logout, component: Component, ...props}) => (
    <ProtectedRoute loggedUser={loggedUser} {...props} component={(props) => <MainLayout loggedUser={loggedUser} logout={logout}>  <Component {...props}/> </MainLayout>} />
)

const AdminMainLayoutProtectedRoute = ({loggedUser, logout, component: Component, ...props}) => (
    <ProtectedRoute loggedUser={loggedUser} {...props} component={(props) => loggedUser.is_admin ?  <MainLayout loggedUser={loggedUser} logout={logout}>  <Component {...props}/> </MainLayout> : <NotFoundPage/>} />
)

const ProtectedRoute = ({loggedUser, component: Component, ...props}) => (
    <Route {...props} render={(props) => (!!loggedUser ? <Component {...props}/>: <Redirect to="/prihlaseni"/>)} />
)

const mapStateToProps = (state) => ({
    loggedUser: getLoggedUser(state),
    isFetching: isFetching(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({logout}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);