import React from 'react';
import {FAILURE, SUCCESS} from "../utils/constants";

const ResultAlert = ({successMsg, failureMsg, status}) => (
    <>
        {status && status === SUCCESS &&
        <div className="alert alert-success" role="alert">
            {successMsg}
        </div>}

        {status && status === FAILURE &&
        <div className="alert alert-danger" role="alert">
            {failureMsg}
        </div>}
    </>
);

export default ResultAlert;