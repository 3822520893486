import React, {useEffect, useState} from 'react';
import {FAILURE, SUCCESS} from "../utils/constants";
import Spinner from "../components/Spinner";
import ResultAlert from "../components/ResultAlert";
import {NavLink} from "react-router-dom";
import {verifyEmail} from "../ducks/loggedUser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

function EmailVerificationPage({verifyEmail}) {
    const [status, setSuccess] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        verifyEmail(urlParams.get('token'))
            .then(() => setSuccess(SUCCESS))
            .catch(() => setSuccess(FAILURE))
    }, []);

    return (
        <div className="l-form">
            <ResultAlert status={status} successMsg="Email úspěšně ověřen" failureMsg="Chyba při ověření" />
            {status && <NavLink to="/">Zpět domů</NavLink>}
            {!status && <Spinner />}
        </div>
    );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({verifyEmail}, dispatch);

export default connect(null, mapDispatchToProps)(EmailVerificationPage);