import {createModel} from "../utils/functions";

const User = createModel('User', (json) => ({
    id: json.id,
    is_admin: json.is_admin,
    email: json.email,
    name: json.name,
    surname: json.surname,
    phone_number: json.phone_number,
    company_name: json.company_name,
    crn: json.crn,
    vat: json.vat,
    street: json.street,
    city: json.city,
    postal_code: json.postal_code,
    new_unverified_email: json.new_unverified_email,
    discounts:  json.discounts,
}));

export default User;