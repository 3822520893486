import React, {useState} from 'react';
import FormInput from "./FormInput";
import {EMAIL_VALIDATION_PATTERN} from "../utils/constants";
import {useForm} from "react-hook-form";
import {handleEmailUniqueError, setFormErrors} from "../utils/functions";
import FormCheckbox from "./FormCheckbox";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserProfileForm = ({user, submitHandler, isLoggedUserAdmin}) => {
    const {watch, handleSubmit, register, errors, reset, setError} = useForm({defaultValues: user});
    const [editForm, setEditForm] = useState(false)
    const discounts = watch('discounts');

    const onSubmit = (values) => submitHandler(values)
        .then((data) => {
            setEditForm(false);
            data && reset(data);
        })
        .catch(({errors}) => errors && setFormErrors(setError, errors))

    return (
        <div className="profile">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Osobní údaje</h2>
                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Jméno" name="name" disabled={!editForm}/>
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Přijímení" name="surname"
                                   disabled={!editForm}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Email" name="email" disabled={!editForm}
                                   validations={{
                                       required: 'Required',
                                       pattern: {value: EMAIL_VALIDATION_PATTERN}
                                   }}
                                   customErrorHandler={handleEmailUniqueError}
                                   smallText={user.new_unverified_email && (`Nepotvrzený email ${user.new_unverified_email}`)}/>
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Telefonní číslo" name="phone_number"
                                   disabled={!editForm}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Heslo" name="password" type="password"
                                   disabled={!editForm} validations={{}}/>
                    </div>
                    <div className="col-sm"/>
                </div>

                <h2>Fakturační údaje</h2>

                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Název Společnosti" name="company_name"
                                   disabled={!editForm} validations={{}}/>
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="IČO" name="crn" disabled={!editForm}
                                   validations={{}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="DIČ" name="vat" disabled={!editForm}
                                   validations={{}}/>
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Ulice, č.p." name="street"
                                   disabled={!editForm} validations={{}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Město" name="city" disabled={!editForm}
                                   validations={{}}/>
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="PSČ" name="postal_code"
                                   disabled={!editForm} validations={{}}/>
                    </div>
                </div>

                <h2>Slevy</h2>
                {user.discounts.map(((discount, index) =>
                        <div key={discount.name} className="row align-items-center">
                            <div className="col-lg-4"><label>{discount.description}</label></div>
                            <div className="col-lg-2">
                                <input
                                    type="hidden"
                                    name={`discounts[${index}].name`}
                                    ref={register()}
                                />


                                <div className="input-group mb-2 mr-sm-2">
                                    <input
                                        className={`form-control`}
                                        name={`discounts[${index}].value`}
                                        disabled={!editForm || !isLoggedUserAdmin || !discounts[index].marked}
                                        ref={register()}
                                    />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">%</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div className="form-group">
                                    <FormCheckbox register={register} errors={errors} name={`discounts[${index}].marked`} disabled={!editForm || !isLoggedUserAdmin} validations={{}} />
                                </div>
                            </div>
                        </div>
                ))}
                {isLoggedUserAdmin && <small>
                    <FontAwesomeIcon icon={faInfo}/>
                    <span className="ml-2">Pro využití globální hodnoty dané slevy jen zaškrtni. Pro přepsání globální hodnoty pak vyplň hodnotu slevy.</span>
                </small>}

                {editForm && <div className="row mt-5">
                    <div className="col-sm">
                        <button type="submit" className="btn btn-block btn-primary">Uložit změny</button>
                    </div>
                    <div className="col-sm">
                        <button type="button" className="btn btn-block btn-secondary"
                                onClick={() => setEditForm(false) || reset()}>Zrušit změny
                        </button>
                    </div>
                </div>}
            </form>

            {!editForm && <div className="row mt-5">
                <div className="col-sm">
                    <button className="btn btn-block btn-secondary" onClick={() => setEditForm(true)}>Změnit údaje
                    </button>
                </div>
                <div className="col-sm"/>
            </div>}
        </div>
    );
};

export default UserProfileForm;