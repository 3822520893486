import axios from 'axios';
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401 && error.response.config.url !== "/users/me") {
        //TODO - refactor to browserHistory.push instead of hard refresh
        window.location.replace("/prihlaseni");
    }
    throw error;
});

export function doGet(url, queryParams) {
    return axios.get(url + (queryParams ?  `?${querystring.encode(queryParams)}` : ''), {withCredentials: true});
}

export function doPatch(url, body) {
    return axios.patch(url, body, {withCredentials: true});
}

export function doPost (url, body) {
    return axios.post(url, body, {withCredentials: true});
}

export function doDelete (url, queryParams) {
    return axios.delete(url + (queryParams ?  `?${querystring.encode(queryParams)}` : ''), {withCredentials: true});
}