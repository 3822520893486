import React from 'react';
import LeftSidebar from "./LeftSidebar";

const MainLayout = ({loggedUser, children, logout}) => (
    <div className="l-main">
        <LeftSidebar loggedUser={loggedUser} logout={logout}/>
        <div className="content">
            {children}
        </div>
    </div>
);

export default MainLayout;