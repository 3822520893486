import React from 'react';

const FormInput = ({register, label, name, value, errors, validations = {required: 'Required'}, disabled = false}) => (
    <div className="form-group">
        <div className="form-check">
            <input type="checkbox"
                   className={`form-check-input ${(errors[name] && "is-invalid") || ''}`}
                   name={name}
                   value={value}
                   disabled={disabled}
                   ref={register(validations)}/>


            <label className="form-check-label">{label}</label>

            {errors[name] && errors[name].type === 'required' && <div className="invalid-feedback">Pole je povinné</div>}
        </div>
    </div>
);

export default FormInput;