import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {doPost} from "../core/fetch";
import {Link} from "react-router-dom";
import FormInput from "../components/FormInput";
import FormCheckbox from "../components/FormCheckbox";
import {EMAIL_VALIDATION_PATTERN, FAILURE, SUCCESS} from "../utils/constants";
import ResultAlert from "../components/ResultAlert";
import {handleEmailUniqueError, setFormErrors} from "../utils/functions";


function RegistrationPage() {
    const { handleSubmit, register, errors, reset, setError} = useForm();
    const [status, setStatus] = useState(null)

    const onSubmit = values => {
        doPost("/users", values)
            .then(() => setStatus(SUCCESS) || reset())
            .catch(({response}) => {
                if (response.data.data) {
                    setFormErrors(setError, response.data.data)
                } else {
                    setStatus(FAILURE)
                }
            })
    };

    return (
        <div className="l-form">
            <ResultAlert status={status}
                         successMsg="Registrace proběhla úspěšně, ještě prosím registraci potvrďte odkazem, který sme vám zaslali na váš email."
                         failureMsg="Registrace selhala. Zkuste to prosím později, nebo kontaktujte naši podporu."
            />

            <form onSubmit={handleSubmit(onSubmit)} className="form-registration">
                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Jméno" name="name"/>
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Přijímení" name="surname"/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Email" name="email" validations={{
                            required: 'Required',
                            pattern: {value: EMAIL_VALIDATION_PATTERN}
                        }} customErrorHandler={handleEmailUniqueError} />
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Telefonní číslo" name="phone_number" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Heslo" name="password" type="password" />
                    </div>
                    <div className="col-sm"/>
                </div>

                <FormCheckbox register={register} errors={errors} label="Souhlasím se zpracováním osobních údajů" name="gdpr" />

                <button type="submit" className="btn btn-block btn-primary">Vytvořit účet</button>

                <div className="row mt-3">
                    <div className="col-sm">
                        <Link to="/prihlaseni">Přihlásit se</Link>
                    </div>
                    <div className="col-sm text-right">
                        <Link to="#">Zapomenuté heslo</Link>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default RegistrationPage;