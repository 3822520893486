import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import User from "../models/User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-regular-svg-icons";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";

const LeftSidebar = ({loggedUser, logout}) => {
    return (
        <div className="sidebar">
            <NavLink className="sidebar-header" to="/">
                PartyHudba
            </NavLink>

            <nav className="sidebar-items">
                <NavLink className="sidebar-items-item" to="/muj-profil" activeClassName="sidebar-items-active">Můj profil</NavLink>
                <NavLink className="sidebar-items-item" to="/objednavky" activeClassName="sidebar-items-active">Objednávky</NavLink>
                {loggedUser.is_admin && <>
                    <NavLink className="sidebar-items-item" to="/uzivatele" activeClassName="sidebar-items-active">Uživatelé</NavLink>
                </>}
            </nav>

            <div className="sidebar-text">
                <FontAwesomeIcon icon={faUserCircle}/>
                <span className="pl-2"> {loggedUser.name} {loggedUser.surname} </span>
            </div>

            <div className="sidebar-action" onClick={logout}>
                <FontAwesomeIcon icon={faSignOutAlt}/>
                <span className="pl-2"> Odhlásit se </span>
            </div>
        </div>
    )
};

LeftSidebar.propTypes = {
    loggedUser: PropTypes.instanceOf(User),
    logout: PropTypes.func.isRequired,
};

export default LeftSidebar;

