import React from 'react';

const FormInput = ({register, label, name, errors, validations = {required: 'Required'}, customErrorHandler, smallText, ...inputProps}) => {
    return (
        <div className="form-group">
            {label && <label>{label}</label>}

            <input
                className={`form-control ${(errors[name] && "is-invalid") || ''}`}
                {...inputProps}
                name={name}
                ref={register(validations)}
            />

            {smallText && <small>{smallText}</small>}

            {errors[name] && <div className="invalid-feedback">
                {errors[name].type === 'required' && <span>Pole je povinné</span>}
                {['pattern', 'format'].includes(errors[name].type) && <span>Neplatný formát</span>}
                {customErrorHandler?.(errors[name])}
            </div>}
        </div>
    );
};

export default FormInput;