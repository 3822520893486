import React from 'react';
import {getLoggedUser, updateIdentity,} from "../ducks/loggedUser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import UserProfileForm from "../components/UserProfileForm";

function MyProfilePage({loggedUser, updateIdentity}) {

    const onSubmit = (values) => updateIdentity(values)
        .catch(({response}) => {
            const error = new Error;
            error.errors = response.data.data;
            throw error;
        })

    return <UserProfileForm user={loggedUser} submitHandler={onSubmit} isLoggedUserAdmin={loggedUser.is_admin} />;
}

const mapStateToProps = (state) => ({
    loggedUser: getLoggedUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({updateIdentity}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePage);