import React from 'react';
import {NavLink} from "react-router-dom";

const NotFoundPage = () => (
    <div className="l-form">
        <div className="alert alert-danger w-25 text-center" role="alert">
            Stránka nenalazene nebo k ní nemáte přístup
        </div>
        <NavLink to="/">Zpět domů</NavLink>
    </div>
);

export default NotFoundPage;