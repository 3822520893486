import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

function SearchInput({callback, className}) {
    const [searchText, setSearchText] = useState("");

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            callback(searchText)
        }
    }
    return (
        <div className={`input-group ${className}`}>
            <input type="text" className="form-control" placeholder="Vyhledat" value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyDown={handleKeyDown} />
            <div className="input-group-append">
                <button className="btn btn-secondary" type="button" onClick={() => callback(searchText)}>
                    <FontAwesomeIcon icon={faSearch}/>
                </button>
            </div>
        </div>
    );
}

export default SearchInput;