import React, {useEffect, useState} from 'react';
import {List} from "immutable";
import {doGet} from "../core/fetch";
import User from "../models/User";
import {NavLink} from "react-router-dom";
import SearchInput from "../components/SearchInput";
import Spinner from "../components/Spinner";

const UsersPage = () => {
    const [filteredUsers, setFilteredUsers] = useState(null)
    const [allUsers, setAllUsers] = useState(null)

    const filterUsers = (text) => !text ? allUsers : allUsers.filter(user => (
        user.name.includes(text) || user.surname.includes(text) || user.phone_number.includes(text) || user.email.includes(text)
    ));

    useEffect(() => {
        doGet('/users')
            .then((response) => {
                const users = User.fromServerList(response.data.data);
                setFilteredUsers(users);
                setAllUsers(users);
            })
    }, [])

    return !filteredUsers ? <Spinner /> : (
        <div>
            <SearchInput className="w-25" callback={(text) => setFilteredUsers(filterUsers(text))}/>

            <table className="table table-hover mt-3">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Jméno</th>
                        <th scope="col">Přijímení</th>
                        <th scope="col">Telefonní číslo</th>
                        <th scope="col">Email</th>
                        <th scope="col">Počet rezervací</th>
                        <th scope="col">Akce</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user) => <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.name}</td>
                        <td>{user.surname}</td>
                        <td>{user.phone_number}</td>
                        <td>{user.email}</td>
                        {/*TODO - upravit počet rezervací*/}
                        <td>0</td>
                        <td>
                            <NavLink to={`/uzivatele/${user.id}`}><button type="button" className="btn btn-primary">Zobrazit detail</button></NavLink>
                        </td>
                    </tr>)}
                </tbody>
            </table>

        </div>
    );
};

export default UsersPage;