import React, {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom'
import {doGet, doPatch} from "../core/fetch";
import UserProfileForm from "../components/UserProfileForm";
import Spinner from "../components/Spinner";
import User from "../models/User";
import {getLoggedUser} from "../ducks/loggedUser";
import {connect} from "react-redux";

const UserInfoPage = ({loggedUser}) => {
    const {id} = useParams()
    const [user, setUser] = useState(null)

    const onSubmit = (values) => {
        return doPatch(`/users/${id}`, values)
            .then((response) => {
                const user = User.fromServer(response.data.data);
                setUser(user);
                return user;
            })
            .catch(({response}) => {
                const error = new Error;
                error.errors = response.data.data;
                throw error;
            });
    }

    useEffect(() => {
        doGet(`/users/${id}`)
            .then((response) => setUser(User.fromServer(response.data.data)))
    }, [])

    return !user ? <Spinner/> : <UserProfileForm user={user} submitHandler={onSubmit} isLoggedUserAdmin={loggedUser.is_admin}/>;
};

const mapStateToProps = (state) => ({
    loggedUser: getLoggedUser(state),
});

export default connect(mapStateToProps)(UserInfoPage);