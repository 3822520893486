import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {doPost} from "../core/fetch";
import {bindActionCreators} from "redux";
import {login} from "../ducks/loggedUser";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import FormInput from "../components/FormInput";
import {EMAIL_VALIDATION_PATTERN} from "../utils/constants";

function LoginPage({history, login}) {
    const {handleSubmit, register, errors, reset, getValues} = useForm();
    const [error, setError] = useState(null);

    const onSubmit = values => {
        login(values.email, values.password)
            .then(() => history.push(`/`))
            .catch(({response}) => setError(response.data.message))
    };

    const resendVerification = () => {
        doPost("/users/verification", getValues('email'))
            .then(() => reset() | setError(null))
            .catch(() => setError("bad_credentials"))
    }

    return (
        <div className="l-form">
            {error === 'unverified' &&
            <div className="alert alert-danger alert-form" role="alert">
                Váš email není potvrzený. <a onClick={resendVerification}>Klikněte zde</a> pro zaslání nového potvrzovacího emailu.
            </div>
            }

            {error === 'bad_credentials' &&
            <div className="alert alert-danger alert-form" role="alert">
                Neplatné jméno nebo heslo, zkuste to znovu prosím.
            </div>
            }

            <form onSubmit={handleSubmit(onSubmit)} className="form-login">

                <div className="row">
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Email" name="email" validations={{
                            required: 'Required',
                            pattern: {value: EMAIL_VALIDATION_PATTERN}
                        }} customErrorHandler={(error) => error.type === 'unique' && <span>Email je již použit</span>} />
                    </div>
                    <div className="col-sm">
                        <FormInput register={register} errors={errors} label="Heslo" name="password" type="password" />
                    </div>
                </div>

                <button type="submit" className="btn btn-block btn-primary">Přihlásit se</button>

                <div className="row mt-3">
                    <div className="col-sm">
                        <Link to="/registrace">Vytvořit nový účet</Link>
                    </div>
                    <div className="col-sm text-right">
                        <Link to="#">Zapomenuté heslo</Link>
                    </div>
                </div>
            </form>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({login}, dispatch);

export default connect(null, mapDispatchToProps)(LoginPage);